import { Listbox, Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect } from "react"
import DropdownCheckbox from "src/views/components/common/form-controll/Checkbox/DropdownCheckbox"
import Typography from "src/views/components/typography"
import { optionsJobType, IOptionJobType } from "../../../../../utils/data/phisical-register-data"
import Xclear from "./Xclear"
import { useTranslation } from "next-i18next"

interface Props {
  style_type: "vacancy" | "main"
  setSelectedJobType: any
  selectedJobType: IOptionJobType[]
}

const JobTypeDropdown = ({ style_type, setSelectedJobType, selectedJobType }: Props) => {
  const { t } = useTranslation("common")
  const router = useRouter()

  useEffect(() => {
    const param = router.query.job_types
    setSelectedJobType([])
    if (param && typeof param === "string") {
      const paramJobTypeValues = param.split(",")

      setSelectedJobType(optionsJobType.filter(jobType => paramJobTypeValues.includes(jobType.value))) // this should be equal to [  { id: 3, label: 'ჰიბრიდი', value: 'hybrid' }, { id: 4, label: 'თავისუფალი გრაფიკი', value: 'freelance' }]
    }
  }, [router.query.job_types, setSelectedJobType])

  const clear = () => {
    setSelectedJobType([])

    if (router.pathname.includes("vacancy")) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, job_types: "" }
      })
    }
  }

  const handleChange = (event: any) => {
    setSelectedJobType(event)

    if (router.pathname.includes("vacancy")) {
      const currentQuery: any = { ...router.query, job_types: "" }

      delete currentQuery.page

      if (event.length) currentQuery["job_types"] = event.map((jobType: any) => jobType.value).join(",")

      router.push({
        pathname: router.pathname,
        query: currentQuery
      })
    }
  }

  const translatedJobTypes = optionsJobType.map(type => ({
    ...type,
    label: t(type.label)
  }))

  return (
    <Listbox
      value={selectedJobType}
      onChange={handleChange}
      as="div"
      className={"relative hidden h-full md:flex md:flex-1 job-type-dropdown"}
      multiple
    >
      <Listbox.Button className={"relative flex h-full w-full cursor-pointer items-center pl-4 focus:outline-none"}>
        <Image src={"/images/workspaces.svg"} width={24} height={24} className="mr-2" alt={"workspaces"} />
        <div
          // placeholder='მუშაობის ტიპი'
          className={cx(
            "line-clamp-1 w-full cursor-pointer border-r bg-inherit py-1 pr-2 text-left text-2sm text-secondary-100 placeholder:font-tbcx-regular placeholder:text-2sm placeholder:text-secondary-50",
            style_type === "vacancy" && "border-neutral-40"
          )}
        >
          {selectedJobType.length > 0 ? (
            selectedJobType.map(s => t(s.label)).join(", ")
          ) : (
            <Typography type="text" className="text-secondary-50">
              {t("WORK_TYPES")}
            </Typography>
          )}
        </div>
      </Listbox.Button>
      {/* X clear button */}

      <Xclear show={selectedJobType.length > 0} clear={clear} />

      <Transition
        as={"div"}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={cx("absolute left-0 z-10 mt-2.5", style_type === "main" ? "top-[72px] " : "top-[60px] ")}
      >
        <Listbox.Options
          className={"w-[433px] overflow-auto rounded-[14px] border border-neutral-20 bg-white p-2.5 shadow-card-box"}
        >
          {translatedJobTypes.map((jobType: IOptionJobType) => {
            return (
              <Listbox.Option key={jobType.value} value={jobType}>
                {({ selected, active }) => (
                  <DropdownCheckbox label={t(jobType.label)} name={jobType.value} selected={selected} />
                )}
              </Listbox.Option>
            )
          })}
        </Listbox.Options>
      </Transition>
    </Listbox>
  )
}

export default JobTypeDropdown
